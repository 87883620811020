import { Injectable, OnDestroy } from '@angular/core';
import {
  BulkMarkActionsResponse,
  NotificationsLogsResponse,
  SingleNotificationResponse,
  StreamNotificationResponseData,
} from '../Interfaces/notifications';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService implements OnDestroy {
  streamUrl = `${environment.eventUrl}`;
  audioplayer = new Audio('../../assets/sounds/sound2.wav');
  notificationUrl = `${environment.apiBaseUrl}/notification-service/notification`;
  showNotifications: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  showNotifications$: Observable<boolean> =
    this.showNotifications.asObservable();

  notificationsCount: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  notificationsCount$: Observable<number> =
    this.notificationsCount.asObservable();

  private eventSource!: EventSource;
  private notificationsSubject = new Subject<StreamNotificationResponseData>();
  soundEnabledSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    JSON.parse(localStorage.getItem('notificationSoundState') || 'true')
  );
  soundEnabled$: Observable<boolean> = this.soundEnabledSubject.asObservable();

  constructor(private http: HttpClient) {
    this.connect();
  }
  getNotificationLogs(
    page: number,
    searchTerm: string,
    startDate: string,
    endDate: string,
    size: number,
    type: 'organization' | 'test-taker' | ''
  ) {
    return this.http.get<NotificationsLogsResponse>(
      `${this.notificationUrl}?page=${page}&size=${size}&search=${searchTerm}&startDate=${startDate}&endDate=${endDate}&type=${type}`
    );
  }

  setNotificationValue(value: boolean) {
    this.showNotifications.next(value);
  }

  setSoundState(state: boolean) {
    localStorage.setItem('notificationSoundState', JSON.stringify(state));
    this.soundEnabledSubject.next(state);
  }

  getNotificationUpdates(
    page: number,
    size: number,
    type: 'organization' | 'test-taker' | ''
  ) {
    return this.http.get<NotificationsLogsResponse>(
      `${this.notificationUrl}/updates?page=${page}&size=${size}&type=${type}`
    );
  }

  markBulkRead(notificationData: string[]) {
    return this.http.patch<BulkMarkActionsResponse>(
      `${this.notificationUrl}/bulk-mark-read`,
      { notifications: notificationData }
    );
  }

  singleMarkAsRead(id: string) {
    return this.http.patch<SingleNotificationResponse>(
      `${this.notificationUrl}/${id}/mark-read`,
      {}
    );
  }

  markBulkUnread(notificationData: string[]) {
    return this.http.patch<BulkMarkActionsResponse>(
      `${this.notificationUrl}/bulk-mark-unread`,
      { notifications: notificationData }
    );
  }

  bulkDelete(notificationData: string[]) {
    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      body: { notifications: notificationData },
    };
    return this.http.delete<BulkMarkActionsResponse>(
      `${this.notificationUrl}/bulk-delete`,
      options
    );
  }

  singleDelete(id: string) {
    return this.http.delete<SingleNotificationResponse>(
      `${this.notificationUrl}/${id}`
    );
  }

  private connect(): void {
    const token = sessionStorage.getItem('access_token');
    if (!token) {
      return;
    }
    this.eventSource = new EventSource(`${this.streamUrl}/${token}`);

    this.eventSource.onmessage = (event) => {
      const notification = JSON.parse(event.data);
      this.notificationsCount.next(notification.unreadNotificationsCount);
      this.notificationsSubject.next(notification);
      this.soundEnabledSubject.value
        ? this.audioplayer.play()
        : this.audioplayer.pause();
    };

    this.eventSource.onerror = () => {
      this.eventSource.close();
      setTimeout(() => this.connect(), 5000);
    };
  }

  getStreamedNotifications() {
    return this.notificationsSubject.asObservable();
  }

  ngOnDestroy(): void {
    if (this.eventSource) {
      this.eventSource.close();
    }
  }
}
